html {
  font-family: sans-serif;
}

body {
  margin: 0;
  background: linear-gradient(to bottom,#ebebeb 0,#fff 50%) no-repeat;
  font-family: 'Open Sans',sans-serif;
}

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

html * {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

p {
  line-height: 1.5;
}

#root {
  position: relative;
  z-index: 1;
}
